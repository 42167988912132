@import '~material-colors/dist/colors.scss';
@import '../../styles/styles';

.exportLogError {
    @include antdTableRow($md-red-50, $md-red-100);
}

.exportLogWarning {
    @include antdTableRow($md-amber-50, $md-amber-100);
}

.exportLogSuccess {
    @include antdTableRow($md-green-50, $md-green-100);
}

.textNoWrap {
    white-space: nowrap;
}

.responseData {
    font-size: 11px !important;
}
