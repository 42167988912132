.form {
    display: flex;
    flex-direction: column;
    max-width: 450px;
    padding: 5px 10px !important;

    & > * {
        margin-bottom: 6px !important;
    }

    .formButtonsContainer {
        margin-top: 5px;

        display: flex;
        flex-direction: row;
        gap: 6px;
    }
}
