.form {
    display: flex;
    flex-direction: column;
    max-width: 510px;
    padding: 15px 10px !important;
}

.form > * {
    margin-bottom: 6px !important;
}
