.form {
    max-width: 710px;
    padding: 20px 10px 5px 10px !important;
}

.datePicker {
    width: calc(100% - (33px * 2));
}

.datePicker > * {
    width: 100%;
}

.datePickerButton {
    min-width: 33px;
    max-width: 33px;
    width: 33px;
}

.datePickerButton:first-child {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}

.periodContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.periodButtonsContainer {
    display: flex !important;
}
