.wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.switchWrapper {
    display: flex;
}

.switchLabel {
    margin-right: 10px;
}

