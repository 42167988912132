.wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    min-height: 100%;
}

.content {
    display: flex;
    width: 100%;
    padding-bottom: 30px;
    min-width: 0;
}
