.wrapper {
    width: 256px;
    min-height: 100%;
    display: flex;
    flex-direction: column;
}

.item {
    height: 40px;
    display: flex;
    flex-direction: row;
    width: 100%;
}

.activeItem {
    background-color: aliceblue;
}

.menuItem {
    padding: 0 !important;
}

.navLink {
    padding: 0 16px !important;
}
