.weekend {
    background-color: #e6f7ff;
}

th.hoursColumn {
    white-space: break-spaces;
    width: 0;
}

.table thead th {
    position: sticky !important;
    top: 0;
}
.table :global(.ant-table-expanded-row) {
    position: relative;
    z-index: 1;
}

.nameColumn {
    min-width: 200px;
    max-width: 200px;
    width: 200px;
}

.totalColumn {
    min-width: 80px;
    max-width: 100px;
    width: 80px;
}

.nestedTableIndent {
    display: grid;
    grid-template-columns: 50px calc(100% - 50px);
}
.nestedTableIndent:before {
    content: '';
}


