.container {
    width: 100%;
}

.textNoWrap {
    white-space: nowrap;
}

.textPreWrap {
    white-space: pre-wrap;
}

.cursorPointer {
    cursor: pointer;
}

.cursorDefault {
    cursor: default !important;
}

.linkButton {
    margin: 0 !important;
    padding: 0 !important;
    border: none !important;
    height: auto !important;
}

.reportInfoCollapse {
    margin: 0 5px 8px 5px !important;
}

.approximateHoursContainer {
    display: flex;
    flex-direction: row;
    gap: 6px;
}

.calendar {
    margin-bottom: 15px !important;
}
