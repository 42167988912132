@import '~antd/dist/antd.css';

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.monospace, .code {
  font-family: "JetBrains Mono", "Cascadia Mono", "Consolas", monospace;
}

.app-notification {
  white-space: pre-wrap;
  overflow-wrap: break-word;
}

.code {
  background-color: rgba(175,184,193,0.2);
  line-height: 1;
  border-radius: 6px;
  padding: 2px 4px;
}

.date-picker-w100, .date-picker-w100 > * {
  width: 100%;
}

.w-100 {
  width: 100%;
}

.text-nowrap {
  white-space: nowrap
}

.text-pre-wrap {
  white-space: pre-wrap;
}

.p-0 {
  padding: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.ant-result-subtitle {
  overflow-wrap: anywhere !important;
}
