.container {
    display: flex;
    justify-content: space-between;
    gap: 8px;
}

.leftContainer {
    display: flex;
    gap: 5px;
}
