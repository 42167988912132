.form {
    display: flex;
    flex-direction: column;
    max-width: 710px;
    padding: 5px 10px !important;
}

.form > * {
    margin-bottom: 6px !important;
}

.error {
    margin-top: -10px !important;
    margin-bottom: 2px !important;
}
