.form {
    max-width: 800px;
    margin-right: 10px !important;
}

.formButtonsContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.formButtonsSide {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
}

.templateContent {
    font-size: 12px !important;
}

.formHeading {
    margin-top: -2px !important;
    margin-bottom: -12px !important;
}

.variablesInfoModalContent {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.variablesInfoModalDefaultVariablesHeading {
    margin-bottom: 5px;
    font-weight: bold;
}

.variablesInfoModalDefaultVariablesList {
    display: flex;
}
